import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";

export function IconTooltipButton({
    iconName,
    label,
    iconOnly,
    iconClassName,
    usePopover,
    infoTitle,
    infoText,
    buttonVariant,
    buttonSize,
    buttonDisabled,
    onClick,
}) {
    return (
        <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            rootClose="true"
            delay={400}
            overlay={
                !usePopover ? (
                    <Tooltip id={`popover-positioned-top`}>
                        {infoTitle && <h5>{infoTitle}</h5>}
                        {infoText && (
                            <span>
                                {infoText}
                                {buttonDisabled ? " (nicht verfügbar)" : ""}
                            </span>
                        )}
                    </Tooltip>
                ) : (
                    <Popover id={`popover-positioned-top`}>
                        <Popover.Header as="h3">{infoTitle}</Popover.Header>
                        <Popover.Body>{infoText}</Popover.Body>
                    </Popover>
                )
            }
        >
            {iconOnly ? (
                <a
                    className="icon-link"
                    onClick={(e) => {
                        e.preventDefault();
                        onClick && onClick();
                    }}
                    href="#"
                >
                    <i className={`bi bi-${iconName} ${iconClassName}`} />
                </a>
            ) : (
                <Button
                    size={buttonSize}
                    variant={buttonVariant}
                    disabled={buttonDisabled}
                    onClick={onClick}
                >
                    <i className={`bi bi-${iconName} ${iconClassName}`} />
                    {label && <span> {label}</span>}
                </Button>
            )}
        </OverlayTrigger>
    );
}
