import { Trans, useLingui } from "@lingui/react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import img_poweredbyCFT from "../../../public/images/PoweredByCoolFarm.jpg?width=200, image.jpg?width=400 2x";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import { usePostSubmissionEvaluateMutation } from "../../services/api";
import { useAssessment, useAuthToken } from "../../services/hooks";
import { cropLabel } from "../../utils/crops";
import {
    ghgInventorySummary,
    ghgInventoryTotal,
} from "../../utils/ghgInventory";
import { useScrollSpy } from "../../utils/hooks";
import { DoughnutChart } from "../elements/DoughnutChart";
import EmissionsTable from "../shared/EmissionsTable";

export default function FormResult() {
    const { i18n } = useLingui();
    const currentLanguage = useSelector(selectCurrentViewLanguage);

    const authToken = useAuthToken();
    const assessment = useAssessment({ authToken });
    const { assessmentData, assessmentError, assessmentIsLoading } = assessment;

    const dispatch = useDispatch();

    const [postEvaluate, { data, error, isLoading: isEvaluating }] =
        usePostSubmissionEvaluateMutation({
            fixedCacheKey: "evaluate-submission",
        });

    const results = data?.results;
    const area_ha = data?.meta_details.area_ha;
    const yield_t = data?.meta_details.yield_net_kg / 1000;

    const combinedResults = ghgInventorySummary(
        results,
        area_ha,
        yield_t,
        i18n,
        currentLanguage
    );

    const chartData = Object.keys(combinedResults)
        .map((key) => ({
            label: combinedResults[key].label,
            value: combinedResults[key].tonne,
        }))
        .filter((v) => v.value >= 0);

    const totalPerTonne = ghgInventoryTotal(combinedResults, "tonne");
    const totalPerHa = ghgInventoryTotal(combinedResults, "hectare");
    const totalPerTonneInclRemovals = ghgInventoryTotal(
        combinedResults,
        "tonne",
        true
    );
    const totalPerHaInclRemovals = ghgInventoryTotal(
        combinedResults,
        "hectare",
        true
    );

    // Use scrollspy
    useScrollSpy("#scroll-form-navbar");

    return (
        <Container className="mx-auto my-5">
            {error && (
                <Alert key="warning" variant="warning" className="my-5">
                    <h5>{JSON.stringify(error)}</h5>
                </Alert>
            )}

            <Row className="gx-5">
                <Col xs={3} className="border-end d-print-none">
                    <div id="sticky-section-menu" className="sticky-top">
                        <p className="fs-4 my-0">
                            <Trans id="form_result.ghg-inventory" />{" "}
                            {assessmentData?.survey?.year}
                        </p>
                        <p className="fs-5">
                            {assessmentData?.survey?.crop?.name}
                        </p>
                        <p className="fs-5">
                            <Trans id="form_result.results" />
                        </p>
                        <ListGroup
                            defaultActiveKey="#p0"
                            variant="flush"
                            className="my-5"
                            id="scroll-form-navbar"
                        >
                            <ListGroup.Item action href={`#p${0}`}>
                                <Trans id="form_result.list_group.summary" />
                            </ListGroup.Item>
                            {/*<ListGroup.Item action href={`#p${1}`}>
                            <Trans id="form_result.list_group.comparison" />
            </ListGroup.Item>*/}
                            <ListGroup.Item action href={`#p${2}`}>
                                <Trans id="form_result.list_group.method_background" />
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </Col>
                <Col>
                    <div className="float-end">
                        <Button
                            variant="primary"
                            type="button"
                            className="d-print-none"
                            onClick={() => window.print()}
                        >
                            <Trans id="form.main.print_button" />
                        </Button>{" "}
                        <Button
                            variant="outline-primary"
                            type="button"
                            className="d-print-none"
                            onClick={() => location.reload()}
                        >
                            <Trans id="form.main.return_to_entry_form" />
                        </Button>
                    </div>
                    <h1>
                        <Trans id="form_result.your_ghg_inventory" />
                    </h1>
                    <h2 className="mb-5">
                        {assessmentData?.farm?.name},{" "}
                        {assessmentData?.survey?.crop?.name}{" "}
                        {assessmentData?.survey?.year}
                    </h2>
                    {!error && Object.keys(combinedResults).length == 0 && (
                        <Alert variant="secondary">
                            <Trans id="form_result.error_message" />
                        </Alert>
                    )}
                    <div
                        className="form-section border-bottom mb-4 pb-4"
                        id={`p${0}`}
                        key={0}
                    >
                        <h2 className="mt-5 mb-3">
                            {1}. <Trans id="form_result.section.summary" />
                        </h2>
                        {Object.keys(combinedResults).length > 0 && (
                            <Row>
                                <Col lg={5}>
                                    <h4>
                                        <Trans id="form_result.emission_by_source" />
                                    </h4>
                                    <h5 className="mb-3">
                                        kg CO<sub>2</sub>e{" "}
                                        <Trans id="form_result.emission_by_source.per_tonne" />{" "}
                                        {cropLabel(
                                            assessmentData?.survey?.crop,
                                            currentLanguage
                                        )}
                                    </h5>
                                </Col>
                                <div className="w-100"></div>
                                <Col lg={5}>
                                    <DoughnutChart data={chartData} />
                                </Col>
                                <Col lg={7}>
                                    <EmissionsTable
                                        assignment={assessmentData}
                                        evaluation={data}
                                    />
                                </Col>
                            </Row>
                        )}
                    </div>
                    {/*} <div
                        className="form-section border-bottom mb-4 pb-4"
                        id={`p${1}`}
                        key={1}
                    >
                        <h2 className="mt-5 mb-3">
                            {2}. <Trans id="form_result.section.vergleich" />
                        </h2>
                                                    </div>*/}
                    <div
                        className="form-section border-bottom mb-4 pb-4"
                        id={`p${1}`}
                        key={2}
                    >
                        <h2 className="mt-5 mb-3">
                            {2}.{" "}
                            <Trans id="form_result.section.method_background" />
                        </h2>
                        <a href="https://coolfarmtool.org/" target="_blank">
                            <img
                                src={img_poweredbyCFT}
                                alt="Powered by Cool Farm Tool Badge"
                                height="70"
                                className="float-end"
                            />
                        </a>
                        <p>
                            <Trans id="form_result.section.method_background.text" />
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
