import { Trans, useLingui } from "@lingui/react";
import Col from "react-bootstrap/Col";
import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import { useRequireAuthenticated } from "../../services/hooks";
import { ghgInventorySummary } from "../../utils/ghgInventory";
import { DoughnutChart } from "../elements/DoughnutChart";
import EmissionsTable from "../shared/EmissionsTable";

export function AssignmentDetail({
    assignment,
    survey,
    setSelectedAssignment,
}) {
    const { i18n } = useLingui();
    useRequireAuthenticated();

    const latestEvaluation = assignment?.latest_evaluation;
    const results = latestEvaluation?.results || [];
    const area_ha = latestEvaluation?.meta_details?.area_ha;
    const yield_t = latestEvaluation?.meta_details?.yield_net_kg / 1000;
    const summary = ghgInventorySummary(results, area_ha, yield_t, i18n);
    const chartData = Object.keys(summary).map((key) => ({
        label: summary[key].label,
        value: summary[key].tonne,
    }));

    return (
        <Offcanvas
            show={!!assignment}
            onHide={() => setSelectedAssignment(null)}
            className="offcanvas-50"
            placement="end"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    {assignment?.farm?.name}
                    <br />
                    <span className="fs-6">{survey?.title}</span>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Row>
                    <Col lg={4}>
                        {assignment && <DoughnutChart data={chartData} />}
                        {!assignment && (
                            <p className="text-center my-5">
                                <Trans id="survey.select_assessment" />
                            </p>
                        )}
                    </Col>
                    <Col lg={8}>
                        <EmissionsTable assignment={assignment} />
                    </Col>
                </Row>
            </Offcanvas.Body>
        </Offcanvas>
    );
}
