import {
    ArcElement,
    Chart as ChartJS,
    DoughnutController,
    Legend,
    Tooltip,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend, DoughnutController);

export function DoughnutChart({ data }) {
    const dataInternal = {
        labels: data.map((d) => d.label),
        datasets: [
            {
                label: "Abc",
                data: data.map((d) => d.value),
                backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(255, 206, 86, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(255, 159, 64, 0.2)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    font: { size: 12 },
                    usePointStyle: true,
                    pointStyle: "circle",
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.label}: ${Math.round(
                            context.parsed
                        )} kg CO2e`;
                    },
                },
            },
        },
        //aspectRatio: 0.6,
    };

    return <Doughnut data={dataInternal} options={options} />;
}
