// In this file, schema parts are put together to form different data entry form compositions.

import { object } from "yup";
import { buildSurveyParamIndex, mergeDefaults } from "../utils/schemaUtils";
import { carbonStockGenericSchema } from "./parts/carbon_stock/generic";
import { coverCropGenericSchema } from "./parts/cover_crop/generic";
import { coverCropSugarbeetSchema } from "./parts/cover_crop/sugarbeet";
import { cropGenericSchema } from "./parts/crop/generic";
import { cropProtectionGenericSchema } from "./parts/crop_protection/generic";
import { energyGenericSchema } from "./parts/energy/generic";
import { energySugarbeetSchema } from "./parts/energy/sugarbeet";
import { landManagementSimpleArableSchema } from "./parts/land_management/simple_arable";
import { landUseSimpleArableSchema } from "./parts/land_use/simple_arable";
import { limingArableSchema } from "./parts/liming/arable";
import { metaGenericSchema } from "./parts/meta";
import { mineralFertilizationGenericSchema } from "./parts/mineral_fertilizers/generic";
import { mineralFertilizationSugarbeetSchema } from "./parts/mineral_fertilizers/sugarbeet";
import { sectionMetaFields } from "./parts/misc/sectionMetaFields";
import { notesGenericSchema } from "./parts/notes";
import { organicFertilizationGenericSchema } from "./parts/organic_fertilizers/generic";
import { residueManagementArableSchema } from "./parts/residue_management/arable";
import { residueManagementGenericSchema } from "./parts/residue_management/generic";
import { residueManagementSugarbeetSchema } from "./parts/residue_management/sugarbeet";
import { residueManagementSugarcaneSchema } from "./parts/residue_management/sugarcane";
import { soilGenericSchema } from "./parts/soil/generic";
import { transportGenericSchema } from "./parts/transport/generic";
import { wasteWaterGenericSchema } from "./parts/waste_water/generic";
import { energyArableSchema } from "./parts/energy/arable";
import { transportArableSchema } from "./parts/transport/arable";
import { productOffFarmProcessingSchema } from "./parts/off_farm_processing/product";
import { energyOffFarmProcessingSchema } from "./parts/off_farm_processing/energy";
import { waterOffFarmProcessingSchema } from "./parts/off_farm_processing/water_wastewater";
import { packagingOffFarmProcessingSchema } from "./parts/off_farm_processing/packaging";
import { wasteOffFarmProcessingSchema } from "./parts/off_farm_processing/waste";
import { landManagementGenericSchema } from "./parts/land_management/generic";
import { landUseGenericSchema } from "./parts/land_use/generic";
import { transportInternationalSchema } from "./parts/transport/international";
import { supplyChainHerbalSchema } from "./parts/supply_chain/herbal";

// The object fromDefinitions contains the various compositions of schema parts.
// One of these is selected when setting up a survey. They can be generic (containing only generic parts, such as CROP_BASIC) or crop-specific (usually containing both generic AND crop-specific schema parts, such as CROP_SUGAR_BEET).

export const FORM_DEFINITIONS = {
    CROP_BASIC: {
        name: "CROP_BASIC",
        options: {},
        parts: [
            metaGenericSchema,
            cropGenericSchema,
            residueManagementGenericSchema,
            soilGenericSchema,
            coverCropGenericSchema,
            organicFertilizationGenericSchema,
            mineralFertilizationGenericSchema,
            limingArableSchema,
            cropProtectionGenericSchema,
            energyGenericSchema,
            wasteWaterGenericSchema,
            carbonStockGenericSchema,
            transportGenericSchema,
        ],
    },
    CROP_HERBAL: {
        name: "CROP_HERBAL",
        options: {},
        parts: [
            metaGenericSchema,
            supplyChainHerbalSchema,
            cropGenericSchema,
            residueManagementGenericSchema,
            soilGenericSchema,
            coverCropGenericSchema,
            organicFertilizationGenericSchema,
            mineralFertilizationGenericSchema,
            limingArableSchema,
            cropProtectionGenericSchema,
            energyArableSchema,
            wasteWaterGenericSchema,
            landManagementGenericSchema,
            landUseGenericSchema,
            transportInternationalSchema,
        ],
    },
    CROP_ARABLE: {
        name: "CROP_ARABLE",
        options: {},
        parts: [
            metaGenericSchema,
            cropGenericSchema,
            residueManagementArableSchema,
            soilGenericSchema,
            coverCropGenericSchema,
            organicFertilizationGenericSchema,
            mineralFertilizationGenericSchema,
            limingArableSchema,
            cropProtectionGenericSchema,
            energyArableSchema,
            landManagementSimpleArableSchema,
            landUseSimpleArableSchema,
            transportArableSchema,
        ],
    },
    CROP_SUGAR_BEET: {
        name: "CROP_SUGAR_BEET",
        options: {},
        parts: [
            metaGenericSchema,
            cropGenericSchema,
            residueManagementSugarbeetSchema,
            soilGenericSchema,
            coverCropSugarbeetSchema,
            organicFertilizationGenericSchema,
            mineralFertilizationSugarbeetSchema,
            limingArableSchema,
            cropProtectionGenericSchema,
            energySugarbeetSchema,
            landManagementSimpleArableSchema,
            landUseSimpleArableSchema,
            notesGenericSchema,
        ],
    },
    CROP_SUGARCANE: {
        name: "CROP_SUGARCANE",
        options: {},
        parts: [
            metaGenericSchema,
            cropGenericSchema,
            residueManagementSugarcaneSchema,
            soilGenericSchema,
            coverCropGenericSchema,
            organicFertilizationGenericSchema,
            mineralFertilizationGenericSchema,
            limingArableSchema,
            cropProtectionGenericSchema,
            energyArableSchema,
            landManagementGenericSchema,
            landUseGenericSchema,
            transportArableSchema,
        ],
    },
    OFF_FARM_PROCESSING: {
        name: "OFF_FARM_PROCESSING",
        options: {},
        parts: [
            metaGenericSchema,
            productOffFarmProcessingSchema,
            energyOffFarmProcessingSchema,
            waterOffFarmProcessingSchema,
            //wasteOffFarmProcessingSchema,
            packagingOffFarmProcessingSchema,
        ],
    },
};

const schemaFromParts = function (def, assessment, choiceIndex, schemaMethods) {
    const surveyParams = buildSurveyParamIndex(
        assessment?.survey?.params || []
    );
    const surveyDefaults = assessment?.survey?.defaults || {};
    const disableSectionDataQuality =
        surveyParams?.DISABLE_FIELD_SECTION_DATA_QUALITY == "true";

    let uiSchema = [];
    let dataSchema = object({});
    const groups = [];
    const schemaDefaults = {};
    const partOptions = {
        surveyParams: surveyParams,
        farm: assessment?.farm,
        survey: assessment?.survey,
    };
    for (let part of def.parts) {
        let data, ui, defaults, group;
        ({ data, ui, defaults, group } = part(partOptions, schemaMethods));
        if (!disableSectionDataQuality) {
            ({ ui, data } = appendSectionMeta(
                group,
                { data, ui },
                partOptions,
                schemaMethods
            ));
        }

        uiSchema = uiSchema.concat([ui]);
        dataSchema = dataSchema.concat(object({ [group]: data }));
        if (defaults) schemaDefaults[group] = defaults;

        if (!(group in groups)) groups.push(group);
    }
    const defaults = mergeDefaults(schemaDefaults, surveyDefaults);
    return {
        name: def.name,
        uiSchema,
        dataSchema,
        defaultValues: defaults,
        sectionKeys: groups,
    };
};

export function buildSchema(
    formDefName,
    assessment,
    choiceIndex,
    schemaMethods
) {
    if (formDefName in FORM_DEFINITIONS) {
        return schemaFromParts(
            FORM_DEFINITIONS[formDefName],
            assessment,
            choiceIndex,
            schemaMethods
        );
    }

    return null;
}

function appendSectionMeta(group, { data, ui }, partOptions, schemaMethods) {
    if (!ui.type == "section") return { data, ui };

    const { data: metaData, ui: metaUi } = sectionMetaFields(
        group,
        partOptions,
        schemaMethods
    );

    return {
        data: data.concat(metaData),
        ui: { ...ui, children: ui.children.concat(metaUi.children) },
    };
}
