export function mapEmissionSource(s, i18n) {
    return i18n._(`form_results.data_labels.${s}`);
}

export function ghgInventorySummary(
    rawResults,
    area_ha,
    yield_t,
    i18n,
    language = null
) {
    let combinedResults = {};
    for (let res of rawResults) {
        const key = res.emission_source;
        if (!(key in combinedResults)) {
            combinedResults[key] = {
                hectare: 0,
                tonne: 0,
                label: mapEmissionSource(res.emission_source, i18n),
                details: [],
            };
        }
        const per_ha = res.co2e_total_kg / area_ha;
        const per_tonne = res.co2e_total_kg / yield_t;

        combinedResults[key].hectare = combinedResults[key].hectare + per_ha;
        combinedResults[key].tonne = combinedResults[key].tonne + per_tonne;

        // find label translations, if available
        let detail_label = res.emission_source_detail;
        if (res?.detail_meta?.labels && language in res?.detail_meta?.labels) {
            detail_label = res?.detail_meta?.labels[language];
        }

        if (res.emission_source_detail) {
            combinedResults[key].details.push({
                hectare: per_ha,
                tonne: per_tonne,
                label: detail_label,
            });
        }
    }

    return combinedResults;
}

export function ghgInventoryTotal(summary, unitKey, includeRemovals = false) {
    let total = 0;
    for (let rKey of Object.keys(summary)) {
        const r = summary[rKey];
        if (rKey == "co_product") continue; // do not sum co-products
        if (r[unitKey] < 0 && !includeRemovals) continue;
        total += r[unitKey];
    }
    return total;
}
